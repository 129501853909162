<template>
  <div class="pagination">
    <div class="d-flex align-items-center justify-content-between w-100">
      <div class="text--capital text--regular pagination__text">
        showing
        <span class="d-inline-block text--primary mr-3">
          <label for="">
            <select
              v-model.number="pageSize"
              class="select select--lg select--page"
              @change="getAllCreatedTasks()"
            >
              <option
                v-for="psize in pagesizes"
                :key="psize.id"
                :id="psize.id"
                :selected="size"
              >
                {{ psize.size }}
              </option>
            </select>
          </label>
        </span>
        <span class="d-inline-block text--primary">{{
          allItems.currentPage
        }}</span>
        to
        <span class="d-inline-block text--primary">{{
          allItems.totalPages
        }}</span>
      </div>

      <div
        class="
          pagination__buttons
          d-flex
          align-items-center
          justify-content-between
        "
      >
        <button
          class="button button--sm text--regular"
          v-if="allItems.hasPrevious || allItems.hasPreviousPage"
          @click="changeCurrentPage('previous')"
        >
          <ion-icon name="chevron-back-outline"></ion-icon>
        </button>
        <div v-if="!isLargeSizePaged" class="d-flex align-items-center pagination__buttons">
          <button
            class="button text--regular button--sm ml-2"
            v-for="pages in allItems.totalPages"
            :key="pages"
            :class="{
              'button--primary text--white': (allItems.currentPage || allITems.pageIndex) === pages,
            }"
            @click="changeCurrentPage(pages)"
          >
            {{ pages }}
          </button>
        </div>
        <div v-else class="text--capital text--regular pagination__text">
          Page
          <span class="d-inline-block text--primary">{{
            allItems.currentPage
          }}</span>
          of
          <span class="d-inline-block text--primary">{{
            allItems.totalPages
          }}</span>
          from <span class="d-inline-block text--primary">{{ allItems.totalCount }}</span> Records
        </div>
        <button
          class="button text--regular button--sm ml-2"
          v-if="allItems.hasNext || allItems.hasNextPage"
          @click="changeCurrentPage('next')"
        >
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Pagination",
  props: {
    allItems: [Array, Object],
    size: Number,
    pageIndex: Number,
  },
  data() {
    return {
      pagesizes: [
        {
          size: 10,
        },
        {
          size: 20,
        },
        {
          size: 50,
        },
      ],
      pageSize: this.size,
    };
  },
  computed: {
    isLargeSizePaged() {
      return this.allItems?.totalPages > 10;
    }
  },
  methods: {
    getAllCreatedTasks() {
      this.$emit("update-created-task", this.pageSize);
    },
    changeCurrentPage(value) {
      this.$emit("change-page-index", value, this.pageSize);
    },
  },
};
</script>
